import { CacheType, ObjectPool } from '@/models/object-pool';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { ReadonlyJSONObject } from 'replicache';
import { ManagedCache } from './managed-cache';
import {
  MembershipReplicache,
  ProjectReplicache,
  UserReplicache,
} from './replicache';

export class CacheStore {
  private _userCache: ManagedCache<UserReplicache> | null = null;
  private _membershipCache: ManagedCache<MembershipReplicache> | null = null;
  private _projectCache: ManagedCache<ProjectReplicache> | null = null;

  public objectPool: ObjectPool;

  static initializeSingleton(objectPool: ObjectPool) {
    cacheStore = new CacheStore(objectPool);
    return cacheStore;
  }

  constructor(objectPool: ObjectPool) {
    this.objectPool = objectPool;

    makeAutoObservable(this);
  }

  public get userCache() {
    return this._userCache;
  }

  public get membershipCache() {
    return this._membershipCache;
  }

  public get projectCache() {
    return this._projectCache;
  }

  public get finishedSyncing() {
    const caches = [this.userCache, this.membershipCache, this.projectCache];
    return caches.every((cache) => !cache || cache.finishedSyncing);
  }

  public pullAll() {
    this.userCache?.initializeCache();
    this.membershipCache?.initializeCache();
    this.projectCache?.initializeCache();
  }

  public setUserCache(userCache: ManagedCache<UserReplicache>) {
    this.objectPool.removeCache('user');
    this.userCache?.close();
    this.watchCache('user', userCache);
    this._userCache = userCache;
  }

  public setMembershipCache(
    membershipCache: ManagedCache<MembershipReplicache>
  ) {
    this.objectPool.removeCache('membership');
    this.membershipCache?.close();
    this.watchCache('membership', membershipCache);
    this._membershipCache = membershipCache;
  }

  public setProjectCache(projectCache: ManagedCache<ProjectReplicache>) {
    this.objectPool.removeCache('project');
    this.projectCache?.close();
    this.watchCache('project', projectCache);
    this._projectCache = projectCache;
  }

  private watchCache(cacheType: CacheType, managedCache: ManagedCache) {
    managedCache.startWatch({
      onRecordAdded: (key, value) => {
        this.objectPool.addObject(cacheType, key, value as ReadonlyJSONObject);
      },
      onRecordRemoved: (key) => {
        this.objectPool.removeObject(key);
      },
      onRecordUpdated: (key, oldValue, newValue) => {
        this.objectPool.updateObject(key, newValue as ReadonlyJSONObject);
      },
    });
  }
}

export let cacheStore: CacheStore;

export const CacheStoreContext = createContext<CacheStore | null>(null);

export const useCacheStore = () => {
  const cacheStore = useContext(CacheStoreContext);
  if (!cacheStore) {
    throw new Error('useCacheStore must be used within a CacheStoreProvider');
  }
  return cacheStore;
};
